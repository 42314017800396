import React from "react";
import NotFound from "../components/notFound/NotFound";
import SEO from "../components/seo/Seo";
import Layout from "../layouts/layout";

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="404 - Not Found" />
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;

import React, { FC } from "react";
import { navigate } from "gatsby";

import { isBrowser } from "../../utils/browser/browser";
import { Loading } from "@components/Loading/Loading.component";
import { Text } from "@ifgengineering/component-library";
import { PublicRouteProps } from "../../pages/app";

const NotFound: FC<PublicRouteProps> = () => {
  return isBrowser() ? (
    <div>
      <h1>404</h1>
      <h2>{"Oh no! We couldn't find the page you were looking for."}</h2>
      <Text color="BLUE600" type="P16" onClick={() => navigate("/")}>
        Take me back to the homepage
      </Text>
    </div>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default NotFound;

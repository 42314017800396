import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { SITE_URL } from "gatsby-env-variables";

interface Props {
  title: string;
  description?: string;
  image?: string;
  article?: string;
}

const SEO: FC<Props> = ({ title, description, image, article }) => {
  const { pathname } = useLocation();

  const site = {
    siteMetadata: {
      defaultTitle: "Cur8",
      titleTemplate: "%s | Cur8",
      defaultDescription: "Invest in High-Growth Startups",
      siteUrl: `${SITE_URL}`, // No trailing slash allowed!
      defaultImage: "/cur8-logo.svg", // Path to your image you placed in the 'static' folder
      twitterUsername: "@IFguru",
    },
  };

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={title === "Cur8" ? "" : titleTemplate}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default SEO;
